import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
export default {
  components: {
    vueQr: vueQr
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    goBack: function goBack() {
      window.history.go(-1);
    },
    onCopy: function onCopy() {
      this.$copyText(this.qrUrl).then(function (e) {
        _Toast({
          message: '',
          icon: 'success'
        });
      });
    }
  },
  computed: {
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    },
    qrUrl: {
      get: function get() {
        return location.origin + '?c=' + this.userInfo.gid;
      }
    }
  }
};